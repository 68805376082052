.navbar {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: var(--sbs-background);

    svg {
        color: white;
        filter: invert(100%);
        color: black;
        font-size: 25px;
        cursor: pointer;
        margin: 0.25rem 0.5rem;
    }

    .logout {
        margin-top: auto;
        background-color: transparent;
        cursor: pointer;
        border: none;
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
        color: white;
    }



    .header {
        background-color: var(--sbs-primary-variant);

    }

    nav {
        background-color: var(--sbs-primary);
        position: fixed;
        max-width: 300px;
        top: 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: max-width .5s;
        height: 100%;
        z-index: 2;

        &.close {
            max-width: 0;
        }

        .nav-groups {
            margin-top: 1rem;
        }

        .nav-group {
            width: 300px;
            cursor: pointer;

            &:hover {
                background-color: var(--sbs-primary-variant);
            }

            .nav-group-title {

                .title-and-icon {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    img {
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                }

                display: flex;
                justify-content: space-between;
                font-size: 1.5rem;
                color: white;
                padding: 0.5rem;
                align-items: center;


                .icon-container {
                    transition: .5s;
                    transform-origin: center;
                }
            }

            .icon-container {
                transition: .5s;
            }

            .nav-group-routes {
                .route {
                    height: 50px;
                    overflow: hidden;
                    transition: .5s;
                    display: flex;
                    align-items: center;

                    &:hover {
                        // background-color: var(--sbs-primary);
                        background-color: darken($color: #ff5603, $amount: 10);
                    }

                    a {
                        text-decoration: none;
                        color: white;
                        padding: 0.5rem;
                        font-size: 1.2rem;
                        width: 100%;

                    }
                }
            }

            &.close {
                .icon-container {
                    transform: rotate(90deg);
                    
                }

                .nav-group-routes {
                    .route {
                        height: 0;
                        opacity: 0;
                    }
                }
            }
        }
    }
}