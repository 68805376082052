.accordion {
    $midnight: #2c3e50;
    $clouds: #ecf0f1;

    margin-top: 2rem;
    border: 1px solid white;
    border-radius: var(--sbs-border-radius);

    .tabs {

        input {
            position: absolute;
            opacity: 0;
            z-index: -1;
        }
    }

    // Layout


    /* Accordion styles */
    .tabs {
        border-radius: var(--sbs-border-radius);
        overflow: hidden;
        box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);

        .tab {
            width: 100%;
            color: white;
            overflow: hidden;
            position: relative;

            .option-container {
                display: flex;
                gap: 0.5rem;
                position: absolute;
                left: 4rem;
                top: 7px;
            }

            .tab-content {
                background-color: #8d8d8d;
            }

            &-label {
                display: flex;
                justify-content: space-between;
                padding: 1em;
                // background: $midnight;
                border-bottom: 1px solid white;
                font-weight: bold;
                cursor: pointer;

                /* Icon */
                &:hover {
                    background-color: var(--sbs-white-opacity);
                }

                &::after {
                    content: "\276F";
                    width: 1em;
                    height: 1em;
                    text-align: center;
                    transition: all .35s;
                }
            }

            &-content {
                max-height: 0;
                padding: 0 1em;
                border-bottom: 1px solid white;

                // color: $midnight;
                // background: white;
                transition: all .35s;
            }

            &-close {
                display: flex;
                justify-content: flex-end;
                padding: 1em;
                font-size: 0.75em;
                // background: $midnight;
                cursor: pointer;

                &:hover {
                    background: darken($midnight, 10%);
                }
            }
        }

        // :checked
        input:checked {
            +.tab-label {
                // background: darken($midnight, 10%);

                &::after {
                    transform: rotate(90deg);
                }
            }

            ~.tab-content {
                max-height: 100vh;
                padding: 1em;
            }
        }
    }


}