.progress-container {
    background-color: var(--sbs-white-opacity);
    position: relative;
    border-radius: var(--sbs-border-radius);
    padding: 5px 10px;
    
    .progress {
        border-radius: var(--sbs-border-radius);
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        max-width: 100%;
        min-width: 0%;
        background-color: var(--sbs-primary-variant);
    }
    span{
        display: inline-block;
        width: 100%;
        text-align: center;
        position: relative;
        z-index: 2;
    }
}