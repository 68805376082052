.sbs-calendar {
    margin-top: 10px;
    padding-bottom: 8px;
    max-width: 400px;
    .react-calendar__navigation {

        display: flex;
        align-items: center;
        justify-content: center;
        

        .react-calendar__navigation__label {
            color: white;
            font-weight: 800;
            border: none;
            background-color: transparent;
            font-size: 14px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            pointer-events: none;
            display: none;
        }

        .react-calendar__navigation__arrow {


            &.react-calendar__navigation__next2-button,
            &.react-calendar__navigation__prev2-button {
                display: none;
            }

            &.react-calendar__navigation__prev-button,
            &.react-calendar__navigation__next-button {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                border: none;
                width: 23px;
                height: 23px;
                font-size: 23px;
                color: rgb(165, 173, 186);
                display: none;

                &:hover {
                    background-color: #eaecf0;
                    border-radius: 3px;
                }
            }
        }
    }



    .react-calendar__viewContainer .react-calendar__month-view {
        .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr {
            font-size: 11px;
            font-weight: 800;
            text-decoration: none;
            text-transform: uppercase;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .react-calendar__month-view__days {
            margin-top: 7px;

            .react-calendar__tile {
                color: white;
                border-radius: var(--sbs-border-radius);
                background-color: transparent;
                // padding: 4px 9px;
                padding: 3px 0;
                font-size: 14px;
                
                font-weight: 400;
                
                border: 2px solid var(--sbs-background);
                background-color: var(--sbs-primary);
                // padding: 5px;
                
                &:hover {
                    background-color: var(--sbs-primary-variant);
                }
                
                &.react-calendar__tile--now {
                    color: #0052cc;
                    font-weight: 800;
                    position: relative;
                    
                    &:before {
                        content: "";
                        position: absolute;
                        width: 70%;
                        height: 1px;
                        bottom: 3px;
                        left: 15%;
                        right: 15%;
                        border-bottom: 2px solid white;
                    }
                }
                
                &.react-calendar__tile--active {
                    background-color: var(--sbs-primary-variant);
                    color: white;

                    // &:hover {
                    //     background-color: #ebecf0;
                    // }
                }

                &.react-calendar__month-view__days__day--neighboringMonth {
                    color: #6b778c;
                    opacity: .5;
                    pointer-events: none; 
                }
            }

        }
    }
}