.view-customer-page {
    margin-top: 2rem;

    .image-and-basic {
        margin-top: 2rem;
        display: flex;
        gap: 2rem;

        .profile-image {
            img {
                aspect-ratio: 1/1;
                border-radius: 50%;
                object-fit: cover;
                width: 200px;
            }
        }

        .basic-data {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1rem;

            span {
                font-size: 1.2rem;
            }
        }



    }

    .data-container {
        display: flex;
        margin-top: 2rem;

        .add-to-table{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            font-size: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background-color: var(--sbs-primary-variant);
            color: white;
        }

        .data {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            flex: 1;

            .groups-container {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                margin-right: 0.5rem;
            }

            .payments-inputs{
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
            }

            .files-data {



                .files-list {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.5rem;
                    margin-bottom: 1rem;

                    .file-item {
                        display: flex;
                        align-items: center;
                        border-radius: 0.8rem;
                        padding: 0.2rem 0.5rem;
                        background-color: var(--sbs-primary-variant);

                        svg {
                            transform: translateY(3px);
                            filter: invert(100%);
                            cursor: pointer;
                        }
                    }
                }

                .files-upload-container {

                    border: 1px dashed grey;
                    position: relative;
                    width: 100%;

                    .not-file-upload {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        img {
                            width: 200px;
                        }

                        p {
                            font-size: 25px;
                            text-align: center;

                            span {
                                font-weight: 800;
                            }
                        }
                    }


                    .files-upload {
                        width: 100%;
                        height: 300px;
                        opacity: 0;
                    }
                }

            }

        }

        .labels {
            display: flex;
            flex-direction: column;

            .label {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0.5rem;

                svg {
                    font-size: 3rem;
                }

                background-color: var(--sbs-primary-variant);
                cursor: pointer;

                &:hover,
                &.curr {
                    background-color: var(--sbs-primary);
                }
            }
        }
    }

    .data-situation {
        position: fixed;
        top: 5rem;
        left: 3rem;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        font-size: 30px;
        border-radius: 50%;
        background-color: #00701a;

        &.not-updated{
            background-color: #ab000d;
            svg{
                filter: invert(100%);
            }
        }
    }
}