


// Breakpoints
$mobile-breakpoint: 460px;
$narrow-breakpoint: 720px;
$normal-breakpoint: 960px;

:root {
    --sbs-border-radius: 0.25rem;
    --sbs-white-opacity: #ffffff65;
    --sbs-black-opacity: #00000065;
    --sbs-primary: #ff7b3b;
    --sbs-primary-variant: #ff5603;
    --sbs-secondary-typography: #fff7f2;
    --sbs-background: #1b1b1b;
    --sbs-icon-gardient: linear-gradient(45deg,
            hsl(0deg 0% 100%) 0%,
            hsl(240deg 11% 99%) 8%,
            hsl(240deg 11% 99%) 17%,
            hsl(240deg 11% 98%) 25%,
            hsl(235deg 13% 97%) 33%,
            hsl(231deg 15% 95%) 42%,
            hsl(228deg 16% 94%) 50%,
            hsl(226deg 16% 91%) 58%,
            hsl(225deg 16% 89%) 67%,
            hsl(224deg 16% 86%) 75%,
            hsl(242deg 45% 83%) 83%,
            hsl(269deg 53% 75%) 92%,
            hsl(300deg 45% 63%) 100%);
}