.manage-motivational-sentences-page{
    margin-top: 2rem;

    .sbs-table{
        margin-top: 2rem;
    }

    .header{
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin-bottom: 2rem;
    }

    .sbs-popup-container{
        .sbs-input{
            width: 100%;
        }
        .groups-container{
            margin-top: 0.5rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }
    }

}