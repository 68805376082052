.error-container {
    position: fixed;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    
    z-index: 200;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    .error {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        gap: 1rem;
        border-radius: var(--sbs-border-radius);
        // color: white;
        min-height: 60px;
        width: 300px;
        padding-right: calc(50px + 2rem);
        background-color: var(--sbs-background);
        overflow: hidden;
        border: 1px solid white;
        
        .msg{
            text-align: right;
        }

        .f-modal-alert {
            transform: scale(.5);
            position: absolute;
            right: 0;

        }

        &.success {
            // background-color: #338a3e;
        }

        &.danger {
            // background-color: #b61827;
        }

        &.warning {
            // background-color: #c9bc1f;

        }
    }
}