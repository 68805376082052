.tooltip {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    background: rgba(255, 255, 255, .5);
    position: relative;
}


.tooltip:hover {
    -webkit-animation-play-state: paused;
}

.tooltip:hover .info {
    visibility: visible;
}

// 8-32 letters and numbers


.info {
    width: 200px;
    padding: 10px;
    background: rgba(255, 255, 255, 1);
    border-radius: 3px;
    color: black;
    position: absolute;
    visibility: hidden;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, .5);
    left: 0;
    transform: translateX(calc(-100% - 10px));
}

h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    font-size: 20px;
    margin: 0 0 5px 0;
}

p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 12px;
}

.arrow {
    position: absolute;
    width: 0;
    height: 0;
    rotate: -90deg;
    right: -15px;
    top: 50%;
    transform: translateX(5px);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
}