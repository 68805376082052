.time-clock-container {

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .icon-only {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .view-time-clock-page {
        padding-top: 2rem;
    }

    .footer-container {
        position: relative;
        z-index: 1;

        .footer {
            background-color: var(--sbs-black-opacity);

            .clock-text {
                font-size: 1.4rem;
            }

            svg {
                font-size: 1.5rem;
            }

            .month-container {
                font-size: 2rem;
                overflow: hidden;
                padding-right: 23px;

                &::before {
                    content: '';
                    background-color: var(--sbs-primary-variant);
                    border: 2px solid var(--sbs-primary-variant);
                    height: 150%;
                    transform: rotate(20deg);
                    position: relative;
                    margin-left: 30px;
                }
            }

            .employee-container,
            .sum-container,
            .date-progress-container {
                padding: 10px 0;
            }

        }
    }

    .curr-month-container {
        padding: 1rem;
        border: 1px solid white;
        border-radius: var(--sbs-border-radius);
    }
}