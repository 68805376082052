@font-face {
    font-family: assistant;
    src: url(../../fonts/Assistant/Assistant-ExtraLight.ttf);
    font-weight: 100;
  }
  @font-face {
    font-family: assistant;
    src: url(../../fonts/Assistant/Assistant-ExtraLight.ttf);
    font-weight: 200;
  }
  @font-face {
    font-family: assistant;
    src: url(../../fonts/Assistant/Assistant-Light.ttf);
    font-weight: 300;
  }
  @font-face {
    font-family: assistant;
    src: url(../../fonts/Assistant/Assistant-Regular.ttf);
    font-weight: 400;
  }
  @font-face {
    font-family: assistant;
    src: url(../../fonts/Assistant/Assistant-Medium.ttf);
    font-weight: 500;
  }
  @font-face {
    font-family: assistant;
    src: url(../../fonts/Assistant/Assistant-SemiBold.ttf);
    font-weight: 600;
  }
  @font-face {
    font-family: assistant;
    src: url(../../fonts/Assistant/Assistant-Bold.ttf);
    font-weight: 700;
  }
  @font-face {
    font-family: assistant;
    src: url(../../fonts/Assistant/Assistant-ExtraBold.ttf);
    font-weight: 800;
  }