.sbs-checkbox-container {
    display: block;

    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }

    label {
        position: relative;
        cursor: pointer;

        &:before {
            content: '';
            -webkit-appearance: none;
            background-color: transparent;
            border: 2px solid var(--sbs-primary-variant);
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            // margin-right: 5px;
            border-radius: 0.25rem;
            margin-left: 0.5rem;
        }
    }

    input:checked+label:after {
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        right: 6px;
        width: 8px;
        height: 14px;
        border: solid var(--sbs-primary-variant);
        border-width: 0 4px 4px 0;
        transform: rotate(45deg);
        border-radius: 0.25rem;
    }
}

.sbs-checkbox-container2 {
    display: block;

    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }

    label {
        position: relative;
        cursor: pointer;

        &:after {
            content: '';
            -webkit-appearance: none;
            background-color: transparent;
            border: 2px solid var(--sbs-primary-variant);
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            // margin-right: 5px;
            border-radius: 0.25rem;
            margin-left: 0.5rem;
        }
    }

    input:checked+label:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0px;
        right: 6px;
        width: 8px;
        height: 14px;
        border: solid var(--sbs-primary-variant);
        border-width: 0 4px 4px 0;
        transform: rotate(45deg);
        border-radius: 0.25rem;
    }
}