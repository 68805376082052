.view-product-page{
    margin-top: 2rem;

    .inputs-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    // .sbs-table{
    //     margin-top: 2rem;
    // }

    // .header{
    //     display: flex;
    //     flex-direction: column;
    //     gap: 0.25rem;
    //     margin-bottom: 2rem;
    // }

    // .q_a-inputs{
    //     display: flex;
    //     flex-direction: column;
    //     gap: 0.5rem;
    // }

   

}