table.sbs-table {
    border: 1px solid white;
    border-radius: var(--sbs-border-radius);
    width: 100%;
    font-size: 1.2rem;
    border-spacing: 0;
    border-collapse: none;
    display: table;

    .reorder-buttons {
        display: inline-flex;
        flex-direction: column;
        padding-right: 10px;
        transform: translateY(-5px);


        .reorder-button {
            color: white;
            border: none;
            background-color: transparent;
            height: 10px;
        }
    }


    .table-colspan {
        color: var(--sbs-primary);
    }


    th {
        text-align: right;
        border-bottom: 1px solid var(--sbs-white-opacity);
        padding: 0.5rem 0.25rem;
        color: var(--sbs-primary);
        display: table-cell;

    }

    tr {
        td {
            padding: 0.5rem 0.25rem;
            word-break: break-word;
            word-wrap: break-word;

        }

        &:not(:last-child) {
            td {
                border-bottom: 1px solid var(--sbs-white-opacity);
            }
        }
    }

    .options {
        display: flex;
        gap: 1rem;
    }

}