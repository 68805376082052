.manage-menu-templates-page {
    margin-top: 2rem;

    .sbs-table {
        margin-top: 2rem;

        thead {
            tr {
                th:first-child {
                    width: 20%;
                }
            }
        }
    }

    .header {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin-bottom: 2rem;
    }



}