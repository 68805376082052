.view-menu-page {
  .page-steps {
    padding-top: 2rem;
  }

  .change-meal-button {
    background-color: transparent;
    border: none;
    padding: 0.5rem;

    &:disabled {
      cursor: default;
    }

    svg {
      font-size: 1.5rem;
    }
  }

  .data-situation {
    position: fixed;
    top: 5rem;
    left: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 30px;
    border-radius: 50%;
    background-color: #00701a;
  }
  .data-situation.not-updated {
    background-color: #ab000d;
  }
  .data-situation.not-updated svg {
    filter: invert(100%);
  }
}
