// .home .data-container {
//     justify-content: center;
//     gap: 1.5rem;
//     margin: 0 1rem;

//     .home-data-container {
//         padding: 1rem;
//         border-radius: 0.25rem;
//         border: 1px solid white;
//         display: flex;
//         flex-direction: column;
//         flex: 1;
//         align-items: flex-start;
//         gap: 0.25rem;
//     }
// }

.home {
    .data-container {
        display: flex;
        justify-content: center;
        gap: 1.5rem;
        margin-top: 2rem;

        .home-data-container {
            padding: 1rem;
            border-radius: var(--sbs-border-radius);
            border: 1px solid white;
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: flex-start;
            gap: 0.5rem;
        }
    }

    

    .motivation-sentense{
        display: flex;
        margin-top: 2rem;
        justify-content: center;
        font-size: 1.5rem;
    }

    .task-table{
        margin-top: 2rem;
    }
}