.sbs-dots-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    .dot{
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50px;
        background-color: var(--sbs-primary);
        cursor: pointer;
        
        &.choosen{
            background-color: var(--sbs-primary-variant);
        }
    }
}