.time-picker {

    border: 1px solid white;
    border-radius: 0.25rem;
    align-items: center;
    font-size: 1rem;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input {
        width: 50px;
        padding: 5px 10px;
        border: none;
        text-align: center;
        background-color: transparent;
        color: white;
        font-size: 1rem;


    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}