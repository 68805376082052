.manage-works-page{
    margin-top: 2rem;

    .sbs-table{
        margin-top: 2rem;
    }

    .header{
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin-bottom: 2rem;
    }

}