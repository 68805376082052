.sbs-popup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--sbs-black-opacity);
    top: 0;
    right: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &.close {
        display: none;
    }

    &.open {

        .sbs-popup {
            animation-name: popup;
            animation-duration: .2s;
        }
    }

    .sbs-popup {
        margin-top: 5%;
        background-color: var(--sbs-background);
        min-width: 300px;
        padding: 0.5rem;
        border-radius: var(--sbs-border-radius);
        border: 1px solid white;
        max-height: 85%;
        overflow: auto;
        width: 90%;

        .popup-header {
            display: flex;
            justify-content: space-between;
            padding-bottom: 0.5rem;
            gap: 1rem;

            .close-popup {
                filter: invert(100%);
                cursor: pointer;
            }

            border-bottom: 1px solid var(--sbs-white-opacity);

        }

        .popup-body {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            img {
                max-width: 100%;
                max-height: 200px;
            }

            .files-upload-container {

                border: 1px dashed grey;
                position: relative;
                width: 100%;

                .not-file-upload {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img {
                        width: 200px;
                    }

                    p {
                        font-size: 25px;
                        text-align: center;

                        span {
                            font-weight: 800;
                        }
                    }
                }


                .files-upload {
                    width: 100%;
                    height: 300px;
                    opacity: 0;
                }
            }
        }

        .popup-footer {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            display: flex;
            gap: 0.5rem;
        }
    }
}

@keyframes popup {
    0% {
        transform: scale(0.9);
    }

    // 50%{
    //   transform: scale(1.14);
    // }
    // 60%{
    //   transform: scale(1.11);
    // }
    // 70%{
    //   transform: scale(1.12);
    // }
    // 80%{
    //   transform: scale(1);
    // }
    // 90%{
    //   transform: scale(1.11);
    // }
    100% {
        transform: scale(1);
    }
}