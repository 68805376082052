.view-plan-page {
  .page-steps {
    padding-top: 2rem;
  }
  .train-execise-preview {
    max-width: 100%;

    .title {
      width: 100%;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    //   padding-top: 20px;
    }

    .close {
      padding-top: 45px;
    }
  }
  .trains-execrise-view-plan {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .train-container {
      border: 1px solid white;
      border-radius: var(--sbs-border-radius);
      padding: 1rem;
      background-color: var(--sbs-background);

      .sbs-title {
        margin-bottom: 0.5rem;
      }

      .train-exerise-header {
        border-bottom: 1px solid var(--sbs-white-opacity);
        padding-bottom: 0.5rem;
      }

      .sets,
      .repeats {
        width: 85px;
      }

      .description {
        flex: 1;
      }
    }

    .train-execise-preview {
      background-color: var(--sbs-background);
      padding: 0.5rem;
	  margin-top: 20px;
    }

    .train-exerise {
      background-color: var(--sbs-background);
      padding: 0rem;
      // padding: 0.5rem;
    }

    .change-train-button {
      background-color: transparent;
      border: none;
      padding: 0.5rem;

      &:disabled {
        cursor: default;
      }

      svg {
        font-size: 1.5rem;
      }
    }
  }

  .summary-btns {
    margin-top: 1rem;
  }

  .sbs-title.summary {
    margin-bottom: 1rem;
  }

  .SuperSet {
    border-color: #ff5603 !important;
	// background-color: #ff57030c !important;
    border-top: 2px solid;
	border-right: 2px solid;
	border-left: 2px solid;

    &.superset-child {
      border-bottom: 2px solid;
      border-top: 0px solid;
	  margin-top: 0 !important;
    }
  }

  .excercises {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 12px;
  }

  .excercise {
    background-color: #47474714 !important;
    border-radius: 25px;
    padding: 1rem;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: #4747472f !important;
    }
  }
}
