.login-page {
    margin-top: 2rem;

    .container {
        margin: 0 auto;
        max-width: 300px;

        img {
            width: 100%;
        }
    }

    .forgot-password {
        background-color: transparent;
        border: none;
        color: white;
        font-size: 1rem;
        text-decoration: underline;
    }
}