* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    font-family: assistant;
}

// ::selection {
   
// }

html {
    color: #000;
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;

}


button {
    cursor: pointer;
}

a {
    cursor: pointer;
    color: white;

    &:hover{
        color: #0000EE 
    }
}

#root,
.App {
    height: 100%;
}

.App{
    background-color: var(--sbs-background);
    padding-top: 37px;
    color: white;
}