// flex helpers

.flex {
  display: flex;

  >*.full {
    flex: 1;
  }

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-space-around {
    justify-content: space-around;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.align-center {
    align-items: center;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-end {
    align-items: flex-end;
  }

  .grow {
    flex-grow: 1;
  }

  &.gap-1 {
    gap: 1rem;
  }

  &.gap-0-5 {
    gap: 1rem;
  }


}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grow-1 {
  flex-grow: 1;
}


.w100 {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

// Media helpers

.square-ratio {
  @include aspect-ratio(1, 1);
}

.video-ratio {
  @include aspect-ratio(16, 9);
}

.portrait-ratio {
  @include aspect-ratio(9, 16);
}

.site-block {
  margin-bottom: rem(20px);
}

.page {
  position: relative;
  z-index: 1;
  padding-bottom: 1rem;
}

.button-primary {
  display: inline-block;
  background-color: var(--sbs-primary-variant);
  color: white;
  border-radius: var(--sbs-border-radius);
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--sbs-primary-variant);

  &:hover {
    color: white;
  }

  &:disabled {
    opacity: .5;
    background-color: var(--sbs-primary);
    border: 1px solid var(--sbs-primary);
    cursor: default;

  }

  &.load {
    opacity: .5;
    pointer-events: none;
  }
}

.button-secondary {
  color: white;
  background-color: var(--sbs-background);
  border-radius: var(--sbs-border-radius);
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid white;
}

.min-height {
  min-height: 100px;
}



button.sbs-flow-btn,
a.sbs-flow-btn {
  background-color: var(--sbs-primary-variant);
  color: white;
  border: none;
  font-size: 50px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 10px;
}

.sbs-title {
  font-size: 1.5rem;
  display: block;
}

.sbs-subtitle {
  font-size: 1rem;
  display: block;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--sbs-primary-variant);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.primary-color {
  color: var(--sbs-primary);
}

.white-svg {
  filter: invert(100%);
}

.loader {
  margin: 100px auto;
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load5 {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ff5603, 1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2), 2.5em 0em 0 0em rgba(255, 86, 3, 0.2), 1.75em 1.75em 0 0em rgba(255, 86, 3, 0.2), 0em 2.5em 0 0em rgba(255, 86, 3, 0.2), -1.8em 1.8em 0 0em rgba(255, 86, 3, 0.2), -2.6em 0em 0 0em rgba(255, 86, 3, 0.5), -1.8em -1.8em 0 0em rgba(255, 86, 3, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 86, 3, 0.7), 1.8em -1.8em 0 0em #ff5603, 2.5em 0em 0 0em rgba(255, 86, 3, 0.2), 1.75em 1.75em 0 0em rgba(255, 86, 3, 0.2), 0em 2.5em 0 0em rgba(255, 86, 3, 0.2), -1.8em 1.8em 0 0em rgba(255, 86, 3, 0.2), -2.6em 0em 0 0em rgba(255, 86, 3, 0.2), -1.8em -1.8em 0 0em rgba(255, 86, 3, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 86, 3, 0.5), 1.8em -1.8em 0 0em rgba(255, 86, 3, 0.7), 2.5em 0em 0 0em #ff5603, 1.75em 1.75em 0 0em rgba(255, 86, 3, 0.2), 0em 2.5em 0 0em rgba(255, 86, 3, 0.2), -1.8em 1.8em 0 0em rgba(255, 86, 3, 0.2), -2.6em 0em 0 0em rgba(255, 86, 3, 0.2), -1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 86, 3, 0.2), 1.8em -1.8em 0 0em rgba(255, 86, 3, 0.5), 2.5em 0em 0 0em rgba(255, 86, 3, 0.7), 1.75em 1.75em 0 0em #ff5603, 0em 2.5em 0 0em rgba(255, 86, 3, 0.2), -1.8em 1.8em 0 0em rgba(255, 86, 3, 0.2), -2.6em 0em 0 0em rgba(255, 86, 3, 0.2), -1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 86, 3, 0.2), 1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2), 2.5em 0em 0 0em rgba(255, 86, 3, 0.5), 1.75em 1.75em 0 0em rgba(255, 86, 3, 0.7), 0em 2.5em 0 0em #ff5603, -1.8em 1.8em 0 0em rgba(255, 86, 3, 0.2), -2.6em 0em 0 0em rgba(255, 86, 3, 0.2), -1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 86, 3, 0.2), 1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2), 2.5em 0em 0 0em rgba(255, 86, 3, 0.2), 1.75em 1.75em 0 0em rgba(255, 86, 3, 0.5), 0em 2.5em 0 0em rgba(255, 86, 3, 0.7), -1.8em 1.8em 0 0em #ff5603, -2.6em 0em 0 0em rgba(255, 86, 3, 0.2), -1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 86, 3, 0.2), 1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2), 2.5em 0em 0 0em rgba(255, 86, 3, 0.2), 1.75em 1.75em 0 0em rgba(255, 86, 3, 0.2), 0em 2.5em 0 0em rgba(255, 86, 3, 0.5), -1.8em 1.8em 0 0em rgba(255, 86, 3, 0.7), -2.6em 0em 0 0em #ff5603, -1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 86, 3, 0.2), 1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2), 2.5em 0em 0 0em rgba(255, 86, 3, 0.2), 1.75em 1.75em 0 0em rgba(255, 86, 3, 0.2), 0em 2.5em 0 0em rgba(255, 86, 3, 0.2), -1.8em 1.8em 0 0em rgba(255, 86, 3, 0.5), -2.6em 0em 0 0em rgba(255, 86, 3, 0.7), -1.8em -1.8em 0 0em #ff5603;
  }
}

@keyframes load5 {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ff5603, 1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2), 2.5em 0em 0 0em rgba(255, 86, 3, 0.2), 1.75em 1.75em 0 0em rgba(255, 86, 3, 0.2), 0em 2.5em 0 0em rgba(255, 86, 3, 0.2), -1.8em 1.8em 0 0em rgba(255, 86, 3, 0.2), -2.6em 0em 0 0em rgba(255, 86, 3, 0.5), -1.8em -1.8em 0 0em rgba(255, 86, 3, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 86, 3, 0.7), 1.8em -1.8em 0 0em #ff5603, 2.5em 0em 0 0em rgba(255, 86, 3, 0.2), 1.75em 1.75em 0 0em rgba(255, 86, 3, 0.2), 0em 2.5em 0 0em rgba(255, 86, 3, 0.2), -1.8em 1.8em 0 0em rgba(255, 86, 3, 0.2), -2.6em 0em 0 0em rgba(255, 86, 3, 0.2), -1.8em -1.8em 0 0em rgba(255, 86, 3, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 86, 3, 0.5), 1.8em -1.8em 0 0em rgba(255, 86, 3, 0.7), 2.5em 0em 0 0em #ff5603, 1.75em 1.75em 0 0em rgba(255, 86, 3, 0.2), 0em 2.5em 0 0em rgba(255, 86, 3, 0.2), -1.8em 1.8em 0 0em rgba(255, 86, 3, 0.2), -2.6em 0em 0 0em rgba(255, 86, 3, 0.2), -1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 86, 3, 0.2), 1.8em -1.8em 0 0em rgba(255, 86, 3, 0.5), 2.5em 0em 0 0em rgba(255, 86, 3, 0.7), 1.75em 1.75em 0 0em #ff5603, 0em 2.5em 0 0em rgba(255, 86, 3, 0.2), -1.8em 1.8em 0 0em rgba(255, 86, 3, 0.2), -2.6em 0em 0 0em rgba(255, 86, 3, 0.2), -1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 86, 3, 0.2), 1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2), 2.5em 0em 0 0em rgba(255, 86, 3, 0.5), 1.75em 1.75em 0 0em rgba(255, 86, 3, 0.7), 0em 2.5em 0 0em #ff5603, -1.8em 1.8em 0 0em rgba(255, 86, 3, 0.2), -2.6em 0em 0 0em rgba(255, 86, 3, 0.2), -1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 86, 3, 0.2), 1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2), 2.5em 0em 0 0em rgba(255, 86, 3, 0.2), 1.75em 1.75em 0 0em rgba(255, 86, 3, 0.5), 0em 2.5em 0 0em rgba(255, 86, 3, 0.7), -1.8em 1.8em 0 0em #ff5603, -2.6em 0em 0 0em rgba(255, 86, 3, 0.2), -1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 86, 3, 0.2), 1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2), 2.5em 0em 0 0em rgba(255, 86, 3, 0.2), 1.75em 1.75em 0 0em rgba(255, 86, 3, 0.2), 0em 2.5em 0 0em rgba(255, 86, 3, 0.5), -1.8em 1.8em 0 0em rgba(255, 86, 3, 0.7), -2.6em 0em 0 0em #ff5603, -1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 86, 3, 0.2), 1.8em -1.8em 0 0em rgba(255, 86, 3, 0.2), 2.5em 0em 0 0em rgba(255, 86, 3, 0.2), 1.75em 1.75em 0 0em rgba(255, 86, 3, 0.2), 0em 2.5em 0 0em rgba(255, 86, 3, 0.2), -1.8em 1.8em 0 0em rgba(255, 86, 3, 0.5), -2.6em 0em 0 0em rgba(255, 86, 3, 0.7), -1.8em -1.8em 0 0em #ff5603;
  }
}